import { toSlug } from '@wix/communities-universal/dist/src/services/slug';
import { JAPANESE_PUNCTUATION_MARKS } from '@wix/communities-blog-universal/dist/src/constants/slug';
import { allowYearMonthDayPrefix as fixer } from '@wix/communities-blog-universal/dist/src/utils/slug-fixers';

export const createSlug = (
  field,
  { blackListedSlugs, allowYearMonthDayPrefix, allowedCharacters = [], minLength, allowJapanesePunctuation } = {},
) => {
  const slug = toSlug({
    field,
    blackListedSlugs,
    allowedCharacters: allowJapanesePunctuation
      ? allowedCharacters.concat(JAPANESE_PUNCTUATION_MARKS)
      : allowedCharacters,
    minLength,
  });
  return allowYearMonthDayPrefix ? fixer(slug, { field }) : slug;
};
